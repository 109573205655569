@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
:root {
  --primary: #ff4545;
  --white: #fff;
  --black: #222;
  --blue: #4335bd;
  --grey1: #3a3b3c;
  --grey2: #828282;
  --green1: #45c854;
  --green2: #e5f9e7;
  --pink: #f53f85;
  --brown1: #573a08;
  --brown2: #fffaf3;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
  /* background-color: #F0F0F0; */
  /* font-family: 'Poppins', sans-serif; */
}

body,
input {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  background-color: #fff;
  background-color: var(--white);
  color: #222;
  color: var(--black);
  font-weight: 400;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}

a {
  text-decoration: none;
}
li {
  list-style: none;
}

.container {
  max-width: 130rem;
  margin: 0 auto;
  /* background-color: white; */
}



.d-flex {
  display: flex;
  align-items: center;
}

.section {
  padding: 5rem 0 5rem 0;
  overflow: hidden;
   /* background-color: white; */
}

@media only screen and (max-width: 1200px) {
  .container-slider,
  .container {
    padding: 0 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .container-slider,
  .container {
    max-width: 60rem;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header-container {
  height: 100%;
  width: 100%;
  margin-top: 0px;
  box-shadow: 1px 3px 6px #9e9494;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 999;
}
/* responsive */
.checkbtn{
  font-size: 30px;
  color: black;
  float: right;
  line-height: 80px;
  /* margin-right: 40px; */
  cursor: pointer;
  display: none;
}
#check{
  display: none;
}
@media only screen and (max-width: 996px) {
  .checkbtn{
    display: block;
  }
  .header-nav .header-nav-link {
    position: fixed;
    width: 300px;
    height: 100vh;
    top: 0px;
    background: black;
    top: 80px;
    left: -100%;
    text-align: center;
    display: block;
    transition: all .5s;
    color: white;
  }
  #check:checked ~ .header-nav .header-nav-link{
    left: 0;
  }
  /* .header-nav {
    display: block;
  } */
  .header-logo img {
    display: none;
  }
}

.header-icons {
  margin: 0 0 0 60px;
}

.header-container-icon {
  font-size: 22px;
  cursor: pointer;
  margin: 0 5px;
}

.heading {
  display: flex;
  justify-content: space-between;
  margin: 0px 50px 0;
}
.header-logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-logo h3 {
  /* font-size: 36px; */
  /* text-shadow: 5px -2px 3px #635d5dd9; */
  border: 3px solid var(--black);
  color: var(--primary);
  font-size: 2.5rem;
  font-weight: 700;
  padding: 0.5rem;
}

.header-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-nav-link {
  font-size: 18px;
  padding: 10px 22px;
  color: black;
  display: inline-block;
  font-weight: 400;
}

.header-nav-link:hover {
  color: var(--primary);
  border-bottom: 1px solid var(--primary);
}

.header-user {
  display: flex;
  align-items: center;
}

.header-user-link {
  display: inline-block;
  position: relative;
  z-index: 1;
  font-size: 22px;
  padding: 12px 30px;
  color: black;
}

.count {
  justify-content: center;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  width: 2rem;
  background-color: var(--primary);
  height: 2rem;
  padding: 1.1rem;
  color: var(--white);
  font-weight: 400;
  border-radius: 50%;
}

.header-user-icon {
  /* display: flex; */
  position: relative;
  font-size: 22px;
  /* margin-right: 40px; */
}

.header-user-icon p {
  margin: 0;
  font-size: 16px;
}

.header-user-icon:hover .header-user-acc {
  display: block;
}

.item {
  list-style: none;
}

.item:hover {
  color: var(--primary);
}

.header-user-acc {
  /* cursor: pointer;
  font-size: 16px;
  width: 70px;
  height: 60px;
  background-color: rgb(245, 242, 239, 0.1);
  display: none;
  position: absolute;
  top: 23px;
  right: -8px;
  z-index: 1;
  padding: 5px 5px; */
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 2px;
  width: 14rem;
  top: 3rem;
  font-size: 14px;
  line-height: 3rem;
  background-color: white;
  margin-left: 0rem;
  border-radius: 0.5rem;
  display: none;
  position: absolute;
  transition: 10s;
  cursor: pointer;
  padding: 5px 5px; 

}

/* .header-user-acc > ul {
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 2px;
  width: 20rem;
  top: 8.5rem;
  line-height: 4.5rem;
  position: absolute;
  background-color: whitesmoke;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease 0s;
} */

.link {
  display: flex;
}

.header-user-acc .link {
  display: block;
  color: black;
}

/* search button csss */

.search {
  width: 100%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 1px solid #d6d3d3;
  border-right: none;
  padding: 5px;
  height: 36px;
  border-radius: 4px 0 0 4px;
  outline: none;
  color: black;
}

/* .searchTerm:focus{
  color: #00B4CC;
} */

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #d6d3d3;
  background:  var(--white);
  text-align: center;
  color: white;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
  outline: none;
}
/*Resize the wrap to see the search bar change!*/
.wrap{
  width: 30%;
  position: absolute;
  top: 50%;
  left: 64%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* FOOTER */

.footer {
  background-color: var(--black);
  padding: 6rem 1rem;
  line-height: 3rem;
}

.footer-center span {
  margin-right: 1rem;
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: var(--white);
}

.footer-center a:link,
.footer-center a:visited {
  display: block;
  color: #f1f1f1;
  font-size: 1.4rem;
  transition: 0.6s;
}

.footer-center a:hover {
  color: var(--primary);
}

.footer-center div {
  color: #f1f1f1;
  font-size: 1.4rem;
}

.footer-center h3 {
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: var(--white);
}

.footer .payment-methods {
  margin-top: 2rem;
}

@media only screen and (max-width: 998px) {
  .footer-container {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .footer-container {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }
}

/* PROMOTION */
.section {
  padding: 4rem 0 5rem 0;
}

.section .title {
  text-align: center;
  margin-bottom: 5rem;
}

.section .title h2 {
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.section .title span {
  color: var(--grey2);
}

.promotion-layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  gap: 3rem;
}

.promotion-item {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.promotion-item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: all 500ms ease-in-out;
}

.promotion-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: var(--white);
  text-align: center;
  z-index: 3;
}

.promotion-content h3 {
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--white);
  border-radius: 5rem;
  padding: 0.7rem 1.4rem;
  font-size: 1.7rem;
  font-weight: inherit;
  margin-bottom: 1rem;
  transition: all 300ms ease-in-out;
}

.promotion-content a {
  transition: all 300ms ease-in-out;
  font-size: 1.4rem;
  color: var(--white);
}

.promotion-content a:hover {
  color: var(--primary);
}

.promotion-item:hover .promotion-content h3 {
  background-color: var(--white);
  color: var(--black);
}

.promotion-item:hover img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.promotion-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease-in-out;
}

.promotion-item:hover::after {
  visibility: visible;
  opacity: 1;
}

/* PRODUCTS */
.section .product-layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 3rem;
  gap: 3rem;
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 1.6rem;
}

.section .product-layout .product:hover {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.section.products {
  margin-top: 0.5rem; 
}

.product {
  overflow: hidden;
}

.product .img-container {
  position: relative;
  cursor: pointer;
}

.product .img-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.product .bottom {
  padding: 1rem;
  text-align: center;
}

.product .bottom a {
  margin-bottom: 1rem;
  font-weight: inherit;
  font-size: 1.5rem;
  color: var(--black);
}

.product .bottom a:hover {
  color: var(--primary);
}

.product .bottom span {
  color: var(--primary);
  font-size: 1.8rem;
}

.product .bottom .cancel {
  font-size: 1.4rem;
  color: var(--grey2);
  text-decoration: line-through;
}

.product .addCart {
  position: absolute;
  right: 0;
  bottom: 0.6rem;
  background-color: var(--white); 
  border-radius: 50%;
  padding: 1.3rem 1.6rem;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
}

.product:hover .addCart {
  border-radius: 1rem 0 0 0;
}

.product .addCart:hover {
  background-color: var(--primary);
  color: var(--white);
}

.product i {
  transition: all 300ms ease-in-out;
}

.product .side-icons {
  position: absolute;
  right: 0;
  top: 30%;
  -webkit-transform: translate(80%, -50%);
          transform: translate(80%, -50%);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  transition: all 500ms ease-in-out;
}

.product .side-icons span {
  font-size: 1.4rem;
  background-color: var(--white);
  margin: 0.3rem;
  padding: 1rem 1.3rem;
  border-radius: 50%;
  transition: all 300ms ease-in-out;
}

.product .side-icons span:hover {
  background-color: var(--primary);
  color: var(--white);
}

.product:hover .side-icons {
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
}

/* section-slider */
.section-slider {
  padding: 0rem 0 5rem 0;
  overflow: hidden;

}
.container-slider {
  max-width: 130rem;
  margin: 0 auto;
  /* width: 920px;
  height: 420px; */
}

/* .container-slider img {
  object-fit: cover;
}  */
/* PRODUCT DETAILS */

.product-detail {
  margin-top: 5rem;
}

.product-detail .details {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  grid-gap: 7rem;
  gap: 7rem;
}

.product-detail .left {
  display: flex;
  flex-direction: column;
  /* border: 1px solid rgb(233, 229, 229); */
}

.product-detail .left .main {
  text-align: center;
  background-color: #f6f2f1;
  margin-bottom: 2rem;
  height: 45rem;
  padding: 3rem;
  border-bottom: 1px solid rgb(233, 229, 229);
}

.product-detail .left .main img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.product-detail .thumbnails {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  margin-top: -1rem;
}

.product-detail .thumbnail {
  /* height: 10rem; */
  background-color: #f6f2f1;
  text-align: center;
  z-index: 0;
}

.product-detail .thumbnail img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: all 100ms ease-in-out;
}

.product-detail .thumbnail img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.product-detail .right span {
  display: inline-block;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.product-detail .right h1 {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}

.product-detail .right .price {
  font-size: 600;
  font-size: 2rem;
  color: var(--primary);
  margin-bottom: 2rem;
}

.product-detail .right div {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.product-detail .right select {
  font-family: "Poppins", sans-serif;
  width: 7rem;
  padding: 0.3rem;
  border: 1px solid var(--grey1);
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;
}

.product-detail .right .product-status {
  display: block;
  font-weight: 600;
  margin-bottom: 2rem;
}

.product-detail form {
  margin-bottom: 2rem;
}

.product-detail form span {
  position: absolute;
  top: 50%;
  right: 1rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 2rem;
  z-index: 0;
}

.product-detail .form .addCart {
  display: inline-block;
  background: var(--primary);
  padding: 0.8rem 4rem;
  color: var(--white);
  border-radius: 3rem;
}

.product-detail h3 {
  text-transform: uppercase;
  margin-bottom: 1rem;
}
/* detail */
/* .container-detail {
  max-width: 114rem;
  margin-top: -200rem;
} */

/* promo-detail */
.promotion-detail {
  display: flex;
  justify-content: flex-start;
}

.promotion-detail >div {
  padding: 4px 0;
  /* width: 26px; */

}

.promotion-detail >div:nth-of-type(1) {
  width: 26px;
}
.promotion-detail >div:nth-of-type(2) {
  color: #2F80ED;
  cursor: pointer;
  width: calc(100% - 26px);
  font-weight: 600;
  display: -webkit-flex;
  flex-direction: row;
}
.promotion-detail >div:nth-of-type(2) >i {
  font-size: 20px;
  color: #2F80ED;
  margin-left: 5px;
}

/* rate */
.container-rating {
  width: 100%;
  max-width: 1400px;
  /* margin-right: auto; */
  /* margin-left: auto; */
}

.btn-comment{
  background: var(--white);
  border: 1px solid var(--grey2);
  border-radius: 10%;
  height: 54px;
  margin-left: 18px;
  border-radius: 4px;
  padding: 4px 30px;
  /* outline: none; */
  cursor: pointer;
  text-decoration: none;
}

.btn-comment:hover {
  background: var(--primary);
  color: var(--white);
}
/* tab thông tin sản phẩm */
.container-info {
  width: 320px;
}

.container-info > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  /* margin-bottom: 0.5rem; */
}
.descrip {
  width: 100%;
  max-width: 1400px;
  border-top: 1px solid #e6e4e4 ;
  text-align: justify;
}
.descrip >p {
  margin: 2rem auto;
  line-height: 25px;
}

/* commnent */
.comment-content {
  padding: 20px 0;
  width: 100%;
}

.comment-list > li {
  display: flex;
  padding-bottom: 24px;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #e6e4e4;
}

.avt {
  flex-basis: 200px;
  height: 100%;
}
.rating-box {
  width: calc(100% - 200px);
  height: 100%;
}

.rating-box > p {
  margin-top: 10px;
  text-align: justify;
}

.rating-box > div:nth-child(1) {
  color: #b3b3b3;
}

.content-comment {
  max-width: 130rem;
  margin: -1.5rem auto;
  border-radius: 0.5rem;
  background-color: white;
  padding: 0 2rem;
  margin-bottom: 5rem;
}
.cart {
  margin: 10rem auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.cart-info {
  display: flex;
  flex-wrap: wrap;
}

/* .cart-info span {
  color: var(--grey2);
} */

th {
  text-align: left;
  padding: 0.7rem;
  color: var(--white);
  background-color:#40a9ff;
  font-weight: bold;
}

td {
  padding: 1rem 0.5rem;
}

td input {
  width: 5rem;
  height: 3rem;
  padding: 0.5rem;
  outline: none;
}

td a {
  color: var(--primary);
  font-size: 1.4rem;
}

td a:hover {
  color: var(--primary);
}

td p {
  color: var(--black);
}

td img {
  width: 8rem;
  height: 8rem;
  margin-right: 1rem;
}

.total-price {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 2rem;
}

.total-price button {
  display: inline-block;
  background-color: #40a9ff;
  color: var(--white);
  font-size: 1.6rem;
  padding: 1.3rem 2rem;
  font-weight: 500;
  border-radius: 3rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.total-price table {
  border-top: 3px solid #40a9ff;
  width: 100%;
  max-width: 35rem;
}

td:last-child {
  text-align: right;
}

th:last-child {
  text-align: right;
}

/* quantity */
/* .container-quantity {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.product-quantity {
  width: 10rem;
  display: block;

}

.product-quantity > input {
  max-width: 6rem;
  margin-top: 1rem;
  margin-right: .5rem;
  padding: 0.75rem 0;
  text-align: center;
  border-top: 1px solid grey;
  border-left: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.quantity-selectors-container {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
}

.quantity-selectors {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.quantity-selectors button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  border-radius: 0;
  border: 1px solid grey;
  background-color: #fff;
  color: grey;
  width: 2rem;
}

.quantity-selectors button:first-child {
  border-bottom: 0;
}

.quantity-selectors button:hover {
  cursor: pointer;
}

.quantity-selectors button[disabled] {
  cursor: not-allowed;
}

.quantity-selectors button[disabled] span {
  opacity: 0.5;
} */


/* quatity */
.container-cart {
  width: 45%;
  margin-right: 100px;
}

.quantity-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* margin: 0 -3px; */
  /* margin-right: 3px; */
  background: #eaeaea;
  border-radius: 10px;
  padding: 0.5rem 0.6rem;
}

.quantity-btn {
  background: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 0px 3px;
  cursor: pointer;
}
.quantity-btn svg {
  width: 15px;
  height: 15px;
}

.quantity-btn[disabled] {
  cursor: not-allowed;
}

.quantity-input {
  outline: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
}
.quantity-input::-webkit-inner-spin-button,
.quantity-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container1 {
  width: 100%;
  height: 100vh;
  background-image: url(https://images.pexels.com/photos/389818/pexels-photo-389818.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup {
  width: 100%;
  max-width: 40rem;
  min-height: 10rem;
  background-color: white;
  border-radius: 10px;
  padding: 2.5rem 3.5rem;
}

.signup-heading {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}

.login-form-button {
  width: 100%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container1 {
  width: 100%;
  height: 100vh;
  background-image: url(https://images.pexels.com/photos/389818/pexels-photo-389818.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup {
  width: 100%;
  max-width: 40rem;
  min-height: 10rem;
  background-color: white;
  border-radius: 10px;
  padding: 0.5rem 0;
}

.signup-heading {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}

.login-form-button {
  width: 100%;
}

