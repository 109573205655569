/* PROMOTION */
.section {
  padding: 4rem 0 5rem 0;
}

.section .title {
  text-align: center;
  margin-bottom: 5rem;
}

.section .title h2 {
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.section .title span {
  color: var(--grey2);
}

.promotion-layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.promotion-item {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.promotion-item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: all 500ms ease-in-out;
}

.promotion-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  text-align: center;
  z-index: 3;
}

.promotion-content h3 {
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--white);
  border-radius: 5rem;
  padding: 0.7rem 1.4rem;
  font-size: 1.7rem;
  font-weight: inherit;
  margin-bottom: 1rem;
  transition: all 300ms ease-in-out;
}

.promotion-content a {
  transition: all 300ms ease-in-out;
  font-size: 1.4rem;
  color: var(--white);
}

.promotion-content a:hover {
  color: var(--primary);
}

.promotion-item:hover .promotion-content h3 {
  background-color: var(--white);
  color: var(--black);
}

.promotion-item:hover img {
  transform: scale(1.2);
}

.promotion-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease-in-out;
}

.promotion-item:hover::after {
  visibility: visible;
  opacity: 1;
}

/* PRODUCTS */
.section .product-layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 3rem;
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 1.6rem;
}

.section .product-layout .product:hover {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.section.products {
  margin-top: 0.5rem; 
}

.product {
  overflow: hidden;
}

.product .img-container {
  position: relative;
  cursor: pointer;
}

.product .img-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.product .bottom {
  padding: 1rem;
  text-align: center;
}

.product .bottom a {
  margin-bottom: 1rem;
  font-weight: inherit;
  font-size: 1.5rem;
  color: var(--black);
}

.product .bottom a:hover {
  color: var(--primary);
}

.product .bottom span {
  color: var(--primary);
  font-size: 1.8rem;
}

.product .bottom .cancel {
  font-size: 1.4rem;
  color: var(--grey2);
  text-decoration: line-through;
}

.product .addCart {
  position: absolute;
  right: 0;
  bottom: 0.6rem;
  background-color: var(--white); 
  border-radius: 50%;
  padding: 1.3rem 1.6rem;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
}

.product:hover .addCart {
  border-radius: 1rem 0 0 0;
}

.product .addCart:hover {
  background-color: var(--primary);
  color: var(--white);
}

.product i {
  transition: all 300ms ease-in-out;
}

.product .side-icons {
  position: absolute;
  right: 0;
  top: 30%;
  transform: translate(80%, -50%);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  transition: all 500ms ease-in-out;
}

.product .side-icons span {
  font-size: 1.4rem;
  background-color: var(--white);
  margin: 0.3rem;
  padding: 1rem 1.3rem;
  border-radius: 50%;
  transition: all 300ms ease-in-out;
}

.product .side-icons span:hover {
  background-color: var(--primary);
  color: var(--white);
}

.product:hover .side-icons {
  transform: translate(0%, -50%);
}

/* section-slider */
.section-slider {
  padding: 0rem 0 5rem 0;
  overflow: hidden;

}
.container-slider {
  max-width: 130rem;
  margin: 0 auto;
  /* width: 920px;
  height: 420px; */
}

/* .container-slider img {
  object-fit: cover;
}  */