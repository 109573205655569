* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container1 {
  width: 100%;
  height: 100vh;
  background-image: url(https://images.pexels.com/photos/389818/pexels-photo-389818.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup {
  width: 100%;
  max-width: 40rem;
  min-height: 10rem;
  background-color: white;
  border-radius: 10px;
  padding: 2.5rem 3.5rem;
}

.signup-heading {
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}

.login-form-button {
  width: 100%;
}
