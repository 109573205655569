/* PRODUCT DETAILS */

.product-detail {
  margin-top: 5rem;
}

.product-detail .details {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 7rem;
}

.product-detail .left {
  display: flex;
  flex-direction: column;
  /* border: 1px solid rgb(233, 229, 229); */
}

.product-detail .left .main {
  text-align: center;
  background-color: #f6f2f1;
  margin-bottom: 2rem;
  height: 45rem;
  padding: 3rem;
  border-bottom: 1px solid rgb(233, 229, 229);
}

.product-detail .left .main img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.product-detail .thumbnails {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  width: 100%;
  margin-top: -1rem;
}

.product-detail .thumbnail {
  /* height: 10rem; */
  background-color: #f6f2f1;
  text-align: center;
  z-index: 0;
}

.product-detail .thumbnail img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: all 100ms ease-in-out;
}

.product-detail .thumbnail img:hover {
  transform: scale(1.1);
}

.product-detail .right span {
  display: inline-block;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.product-detail .right h1 {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}

.product-detail .right .price {
  font-size: 600;
  font-size: 2rem;
  color: var(--primary);
  margin-bottom: 2rem;
}

.product-detail .right div {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.product-detail .right select {
  font-family: "Poppins", sans-serif;
  width: 7rem;
  padding: 0.3rem;
  border: 1px solid var(--grey1);
  appearance: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;
}

.product-detail .right .product-status {
  display: block;
  font-weight: 600;
  margin-bottom: 2rem;
}

.product-detail form {
  margin-bottom: 2rem;
}

.product-detail form span {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  font-size: 2rem;
  z-index: 0;
}

.product-detail .form .addCart {
  display: inline-block;
  background: var(--primary);
  padding: 0.8rem 4rem;
  color: var(--white);
  border-radius: 3rem;
}

.product-detail h3 {
  text-transform: uppercase;
  margin-bottom: 1rem;
}
/* detail */
/* .container-detail {
  max-width: 114rem;
  margin-top: -200rem;
} */

/* promo-detail */
.promotion-detail {
  display: flex;
  justify-content: flex-start;
}

.promotion-detail >div {
  padding: 4px 0;
  /* width: 26px; */

}

.promotion-detail >div:nth-of-type(1) {
  width: 26px;
}
.promotion-detail >div:nth-of-type(2) {
  color: #2F80ED;
  cursor: pointer;
  width: calc(100% - 26px);
  font-weight: 600;
  display: -webkit-flex;
  flex-direction: row;
}
.promotion-detail >div:nth-of-type(2) >i {
  font-size: 20px;
  color: #2F80ED;
  margin-left: 5px;
}

/* rate */
.container-rating {
  width: 100%;
  max-width: 1400px;
  /* margin-right: auto; */
  /* margin-left: auto; */
}

.btn-comment{
  background: var(--white);
  border: 1px solid var(--grey2);
  border-radius: 10%;
  height: 54px;
  margin-left: 18px;
  border-radius: 4px;
  padding: 4px 30px;
  /* outline: none; */
  cursor: pointer;
  text-decoration: none;
}

.btn-comment:hover {
  background: var(--primary);
  color: var(--white);
}
/* tab thông tin sản phẩm */
.container-info {
  width: 320px;
}

.container-info > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  /* margin-bottom: 0.5rem; */
}
.descrip {
  width: 100%;
  max-width: 1400px;
  border-top: 1px solid #e6e4e4 ;
  text-align: justify;
}
.descrip >p {
  margin: 2rem auto;
  line-height: 25px;
}

/* commnent */
.comment-content {
  padding: 20px 0;
  width: 100%;
}

.comment-list > li {
  display: flex;
  padding-bottom: 24px;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #e6e4e4;
}

.avt {
  flex-basis: 200px;
  height: 100%;
}
.rating-box {
  width: calc(100% - 200px);
  height: 100%;
}

.rating-box > p {
  margin-top: 10px;
  text-align: justify;
}

.rating-box > div:nth-child(1) {
  color: #b3b3b3;
}

.content-comment {
  max-width: 130rem;
  margin: -1.5rem auto;
  border-radius: 0.5rem;
  background-color: white;
  padding: 0 2rem;
  margin-bottom: 5rem;
}